<template>
  <main>
    <div class="d-flex flex-column flex-shrink-0 p-3 bg-dark text-white vh-100 min-vh-100" style="width: 280px;">
      <a href="/admin/users" class="d-flex align-items-center mb-0 mb-md-0 me-md-auto link-light text-decoration-none">
        <!--<svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"/></svg>-->
        <!--<i class="bi bi-table me-2" style="font-size: 1.2rem; padding-top: 2px;"></i>-->
        <img src="@/assets/logo_koehnke_dark_bg.png" alt="" width="40" height="40">
        <span class="ps-2 ms-1 fs-4 fw-light">Stundenzettel</span>
      </a>
      <hr>
      <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
          <router-link to="/admin/users" v-slot="{href, navigate}">
            <a :href="href" @click="navigate" class="nav-link active" aria-current="page">
              <i class="bi bi-people-fill me-2" style="font-size: 1.5rem;"></i>
              Mitarbeiter
            </a>
          </router-link>
        </li>
        <!--
        <li>
          <a href="#" class="nav-link link-dark">
            <svg class="bi me-2" width="16" height="16"><use xlink:href="#speedometer2"/></svg>
            Tätigkeiten
          </a>
        </li>
        -->
      </ul>

      <hr>
      <div class="dropdown" v-if="this.$store.state.user">
        <a href="#" class="d-flex align-items-center link-light text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-person-circle pe-2" style="font-size: 1.5rem;"></i>
          <strong>{{ $store.state.user.email.split('@')[0] }}</strong>
        </a>
        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
          <!-- <li><hr class="dropdown-divider"></li> -->
          <li><a class="dropdown-item" href="#" @click="logout">Abmelden</a></li>
        </ul>
      </div>
    </div>

    <div class="p-0 pe-5 m-0 w-100 admin-layout-content-scrollable-wrapper">
      <router-view />
    </div>
  </main>
</template>

<script>
import {getAuth, signOut} from "firebase/auth";

export default {
  name: "AdminLayout",
  created() {
    document.title = "Stundenzettel | Admin";
  },
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.replace('/admin/login')
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
}
</script>

<style scoped>
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle { outline: 0; }

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}
</style>
