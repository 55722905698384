<template>
  <div class="container-sm" >
    <div class="text-center mt-5">
      <!--<img src="@/assets/login-600x400.png" alt="" width="250" height="166" class="align-self-center">-->
      <img src="@/assets/logo_koehnke_colored.png" alt="" width="80" height="80" class="align-self-center">
    </div>
    <div class="text-center mt-3">
      <h2 class="fw-light">Anmelden</h2>
    </div>
    <div class="row mt-5">
      <div class="col ms-4 me-4">
        <transition name="fade">
          <div class="alert alert-secondary" role="alert" v-if="error">
            {{ error }}
          </div>
        </transition>
      </div>
    </div>
    <div class="row align-items-center pt-5" v-if="isLoading">
      <div class="col d-flex justify-content-center">
        <div class="spinner-grow text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-3" />
      <div class="col ms-4 me-4">
        <form @submit.prevent="signIn">
          <div class="mb-3">
            <label class="form-label form-text">Telefonnummer</label>
            <vue-tel-input v-model="phoneNumber" @on-input="test" id="phoneNumber" ref="phoneNumberInput" :disabled="showCode">
              <template v-slot:arrow-icon><span></span></template>
            </vue-tel-input>
          </div>
          <div class="mb-3" ref="recaptchaContainer">
            <div id="recaptcha-container"></div>
          </div>
          <div class="mb-3" v-show="showCode">
            <label for="smsCode" class="form-label form-text">SMS-Code</label>
            <input type="text" class="form-control form-control-lg" id="smsCode" v-model="password" @input="validateSMSCode" pattern="\d*" maxlength="6" ref="smsCodeInput">
            <div id="smsCodeHelp" class="form-text">Es wurde eine SMS mit einem Code an diese Nummer gesendet. Bitte geben Sie diesen hier ein.</div>
          </div>
        </form>
        <button id="sign-in-button" type="button" class="btn btn-primary btn-lg" @click="signIn" :disabled="isSubmitDisabled" ref="signInButton">Bestätigen</button>
      </div>
      <div class="col-md-3" />
    </div>
  </div>
</template>

<script>
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { ref } from 'vue';

const FIREBASE_LOGIN_ERROR_MESSAGES = {
  "auth/invalid-verification-code" : "Der eingegebene SMS-Code ist falsch. Bitte versuchen Sie es erneut.",
  "auth/invalid-phone-number" : "Die eingegebene Telefonnummer ist falsch. Bitte versuchen Sie es erneut.",
  "auth/captcha-check-failed" : "Das Captcha ist nicht korrekt. Bitte versuchen Sie es erneut."
}


export default {
  data() {
    return {
      isLoading: false,
      phoneNumber: ref(null),
      formattedPhoneNumber: null,
      password: "",
      error: null,

      recaptchaVerifier: null,
      confirmationResult: null,

      showCode: false,
      phoneNumberValid: false,
      codeValid: false,
    };
  },
  mounted() {
    this.loadCaptcha()
    this.$refs.phoneNumberInput.focus()
  },
  methods: {
    loadCaptcha() {
      const auth = getAuth();
      auth.languageCode = 'de';

      this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          console.log("Response = " + response)
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
          this.showCode = true
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        }
      }, auth);
    },
    resetCaptcha() {
      if (this.recaptchaVerifier && this.$refs.recaptchaContainer) {
        this.recaptchaVerifier.clear()
        this.$refs.recaptchaContainer.innerHTML = '<div id="recaptcha-container"></div>'
      }

      setTimeout(() => {
        this.loadCaptcha()
      }, 1000);
    },
    test(number, phoneObject) {
      this.formattedPhoneNumber = phoneObject.number
      this.phoneNumberValid = phoneObject.valid
    },
    validateSMSCode({ type, target }) {
      this.codeValid = (this.showCode) ? this.password.length >= 6 : false
      console.log(type, target)
    },
    signIn() {
        if (this.showCode) {
          this.onCodeSubmit()
        } else {
          this.onSignInSubmit()
        }
    },
    onSignInSubmit() {
      console.log("submit " + this.formattedPhoneNumber)
      const phoneNumber = this.formattedPhoneNumber;
      const appVerifier = this.recaptchaVerifier;
      const auth = getAuth();

      signInWithPhoneNumber(auth, phoneNumber, appVerifier).then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        this.confirmationResult = confirmationResult;
        this.$refs.smsCodeInput.focus();
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        this.error = this.getErrorMessage(error)
        this.resetInputFields()
      });
    },
    onCodeSubmit() {
      const code = this.password;
      this.isLoading = true;
      this.confirmationResult.confirm(code).then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("Logged in user = " + user);

        this.$store.dispatch('setUser');
        this.$router.push('/timesheets')

      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        this.error = this.getErrorMessage(error)
        this.resetInputFields()
      });
    },
    getErrorMessage(error) {
      const errorMessage = FIREBASE_LOGIN_ERROR_MESSAGES[error.code]
      return (errorMessage) ? errorMessage : error.code
    },
    resetInputFields() {
      this.isLoading = false
      this.showCode = false
      this.phoneNumber = null
      this.formattedPhoneNumber = null
      this.password = null
      this.phoneNumberValid = false
      this.codeValid = false

      this.resetCaptcha()
    }
  },
  computed: {
    isSubmitDisabled() {
      if (this.showCode) {
        return !this.phoneNumberValid || !this.codeValid
      } else {
        return !this.phoneNumberValid
      }
    }
  }


};
</script>
