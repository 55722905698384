<template>
  <div class="container-sm">
    <div class="text-center mt-5">
      <img src="@/assets/logo_koehnke_colored.png" alt="" width="80" height="80" class="align-self-center">
    </div>
    <div class="text-center mt-3">
      <h2 class="fw-light">Anmelden</h2>
    </div>
    <div class="row mt-5">
      <div class="col ms-4 me-4">
        <transition name="fade">
          <div class="alert alert-secondary" role="alert" v-if="error">
            {{ error }}
          </div>
        </transition>
      </div>
    </div>
    <div class="row align-items-center pt-5" v-if="isLoading">
      <div class="col d-flex justify-content-center">
        <div class="spinner-grow text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-3" />
      <div class="col ms-4 me-4">
        <form @submit.prevent="onSignInSubmit">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control form-control-lg" id="email" aria-describedby="emailHelp" v-model="email">
          </div>
          <div class="mb-4">
            <label for="password" class="form-label">Passwort</label>
            <input type="password" class="form-control form-control-lg" id="password" v-model="password" autocomplete="on">
          </div>
          <button id="sign-in-button" type="submit" class="btn btn-primary btn-lg" @click="onSignInSubmit">Anmelden</button>
        </form>
      </div>
      <div class="col-md-3" />
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const FIREBASE_LOGIN_ERROR_MESSAGES = {
  "auth/invalid-email" : "Die eingegebene Email ist nicht korrekt. Bitte versuchen Sie es erneut.",
  "auth/internal-error" : "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "auth/wrong-password" : "Das Passwort ist falsch. Bitte versuchen Sie es erneut.",
}

export default {
  data() {
    return {
      email: "",
      password: "",
      error: null,
      isLoading: false,
    };
  },
  mounted() {
    console.log("signInWithEmail")
    const auth = getAuth();
    auth.languageCode = 'de';
  },
  methods: {
    getErrorMessage(error) {
      const errorMessage = FIREBASE_LOGIN_ERROR_MESSAGES[error.code]
      return (errorMessage) ? errorMessage : error.code
    },
    onSignInSubmit() {
      this.isLoading = true
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log("Logged in with user: " + user)
            this.$store.dispatch('setUser');
            this.$router.push('/admin/users')
            this.isLoading = false
          })
          .catch((error) => {
            this.error = this.getErrorMessage(error)
            this.isLoading = false
          });
    }
  },
};
</script>
